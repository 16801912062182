import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import gsap from "gsap/all";
/**
 * Accepts a duration in seconds 
 * and a callback function to execute when the progress is complete
 * TODO: <progress />
 * @param {{
 * onComplete: () => void,
 * duration: number
 * }} param0 
 */
export function ProgressBar({ onComplete, duration }) {
  const { t } = useTranslation();
  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const progressRef = useRef();
  /**
   * @type {React.MutableRefObject<HTMLSpanElement>}
   */
  const secondsRef = useRef();

  useEffect(() => {
    const tl = gsap.timeline();
    const progressEl = progressRef.current;
    const secondsEl = secondsRef.current;
    const onUpdate = () => {
      secondsEl.innerHTML = (duration * (1 - tl.progress())).toFixed() + "s";
    };

    tl.to(progressEl, {
      duration: duration,
      ease: "none",
      transform: "none",
      onUpdate,
      onComplete() {
        onComplete();
        tl.restart();
      },
    });

    return () => {
      tl.revert().kill();
    };
  }, []);

  return (<div className="refresh df fd-c ai-c gap-10 text-center fixel_16_md">
    <div className="line w-full br-16">
      <div className="progress" ref={progressRef}></div>
    </div>
    <p>
      {t("wallet.buySection.provider.refreshMessage")}{" "}
      <span className="color-sec" ref={secondsRef}>
        {duration}s
      </span>
    </p>
  </div>)
}