import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "/components/core/ButtonDefault";
import CopyToClipboard from "react-copy-to-clipboard";
import gsap from "gsap/all";

let sto = null;

const CopyText = ({ title, addr, copyed, onCopy }) => {
  return (
    <>
      <div>
        <p className="fixel_16_md">{title} </p>
        <div className="df ai-c gap-16">
          <p className="fixel_22 mr-t-4 text-ellipsis">{addr}</p>
          <CopyToClipboard text={addr} onCopy={() => onCopy(addr)}>
            <div
              className={
                "icon_copy icon-24 ar-1" + (copyed == addr ? " copyed" : "")
              }
            ></div>
          </CopyToClipboard>
        </div>
      </div>
    </>
  );
};

export default function Transaction(props) {
  const { t } = useTranslation();
  const { onClose, state, data } = props;
  const [copyAddr, setCopyAddr] = useState("");
  const progressRef = useRef();
  const refreshBy = 15;
  const Base64_QR = data.base_qr;

  const onCopy = (addr) => {
    sto && clearTimeout(sto);
    setCopyAddr(addr);
    sto = setTimeout(() => setCopyAddr(""), 1500);
  };

  const onConfirmed = () => {
    //close Wallet popup
    onClose();

    // show popup on Success
    state.setPopUp("confirm", {
      title: t("wallet.buySection.crypto.transaction.purchaseCompleted"),
      subtitle: t("wallet.buySection.crypto.transaction.transferReceived"),
      close: t("wallet.buySection.crypto.transaction.letsPlay"),
    });
  };

  const getTransactionStatus = async () => {
    const url = `cashier/fiat/providers/simplex/create-payment-session`;
    const params = {};

    // const ress = await fetchAPI.post(url, params);  //TODO

    const ress = { success: false };

    return ress.success;
  };

  useEffect(() => {
    const tl = gsap.timeline();
    const progres = progressRef.current;

    tl.to(progres, {
      duration: refreshBy,
      ease: "none",
      transform: "none",
      onComplete: async () => {
        const status = await getTransactionStatus();
        status ? onConfirmed() : tl.restart();
      },
    });

    return () => {
      tl.revert().kill();
    };
  }, []);

  return (
    <>
      <h2 className="fixel_36 text-center">{t("wallet.buySection.crypto.transaction.buyTico")}</h2>

      <div className="df transaction-to-pay w-full ai-st" data-hide-top data-lg>
        <div className="content-left w-full df fd-c">
          <div>
            <p className="fixel_26">{t("wallet.buySection.crypto.transaction.finalizePayment")}</p>
            <p className="fixel_16_sbl">
              {t("wallet.buySection.crypto.transaction.orderWaiting", { uuid: data.uuid })}
            </p>
          </div>

          <div className="df fd-c content-info">
            <div>
              <p className="fixel_16_md">{t("wallet.buySection.crypto.transaction.amountToSend")}</p>
              <p className="fixel_22 mr-t-4">{data.amount} BTC </p>
            </div>

            <CopyText
              title={t("wallet.buySection.crypto.transaction.toAddress")}
              addr={data.address}
              onCopy={onCopy}
              copyed={copyAddr}
            />
            {data.memo && (
              <CopyText
                title={t("wallet.buySection.crypto.transaction.memo")}
                addr={data.memo}
                onCopy={onCopy}
                copyed={copyAddr}
              />
            )}
            <div>
              <p className="fixel_16_md">{t("wallet.buySection.crypto.transaction.toReceive")}</p>
              <div className="mr-t-4 df ai-c gap-6">
                <div className="tico_icon"></div>
                <p className="fixel_22 ">{data.amount}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-right df fd-c">
          <CustomButton
            classname={`go-buy pr`}
            def={40}
            disabled={true}
            title={t("wallet.buySection.crypto.transaction.connectWallet")}
          />
          {Base64_QR && (
            <div className="df fd-c ai-c">
              <div className="qr as-c df cover ar-1">
                <img src={Base64_QR} alt={t("wallet.buySection.crypto.transaction.qrCode")} />
              </div>
              <p className="fixel_16_sbl mr-t-16 text-center">
                {t("wallet.buySection.crypto.transaction.scanQrCode")}
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="refresh df fd-c ai-c gap-16 text-center fixel_16_md">
        <div className="df ai-c gap-16">
          <div className="icon_loader"></div>
          <p>{t("wallet.buySection.crypto.transaction.waitingForTransaction")}</p>

          <div className="info df">
            <div className="info_message show-top">
              <div className="wrapper br-8 text-center">
                <p className="fixel_14">
                  {t("wallet.buySection.crypto.transaction.safeToClose")}
                </p>
              </div>
            </div>
            <div className="icon_info"></div>
          </div>
        </div>
        <div className="line w-full br-16">
          <div className="progress" ref={progressRef}></div>
        </div>
      </div>
    </>
  );
}
