import * as styles from "./TopBanner.module.scss";
import video from "/assets/video/banner-bg.mp4";
import videoMobile from "/assets/video/banner-bg-mobile.mp4";
import ticos from "/assets/img/promotion/ticos.png";
import Counter from "./Counter";
import { Live, Tico } from "./BlinkingText/BlinkingText";
import DelayLink from "/components/core/DelayLink";

const TGE_DATE = "2025-01-27T13:00:00";

export default function TopBanner({ state }) {
  const mobile = state.getState().isMobile;

  return (
    <DelayLink to={"/tico"}>
      <div className={styles.banner}>
        <img src={ticos} alt="Tico coins" className={styles.image} />
        {mobile ? (
          <video
            autoPlay
            muted
            loop
            playsInline
            preload="metadata"
            aria-hidden="true"
            id="mobileBannerBg"
            className={styles.video}
          >
            <source src={videoMobile} type="video/mp4" />
          </video>
        ) : (
          <video
            autoPlay
            muted
            loop
            playsInline
            preload="metadata"
            aria-hidden="true"
            id="desktopBannerBg"
            className={styles.video}
          >
            <source src={video} type="video/mp4" />
          </video>
        )}
        <div />
        {mobile ? (
          <>
            <div className={styles.content}>
              <span className={styles.text}>
                <Tico mobile={false} />{" "}
                <span className={styles.hidden}>Token</span> <span>goes</span>{" "}
                <Live mobile={false} /> <span>in...</span>
              </span>
              <Counter targetDate={TGE_DATE} className={styles.timer} />
            </div>
            <div
              role="button"
              className={`link def pr br-16 type-24 ${styles.button}`}
            >
              $TICO
              <br /> Page
            </div>
          </>
        ) : (
          <>
            <div className={styles.content}>
              <Tico mobile={false} /> <span>Token goes</span>{" "}
              <Live mobile={false} /> <span>in...</span>{" "}
              <Counter targetDate={TGE_DATE} className={styles.timer} />
            </div>
            <div
              role="button"
              className={`link def pr br-16 type-32 ${styles.button}`}
            >
              Check $TICO Page!
            </div>
          </>
        )}
      </div>
    </DelayLink>
  );
}
