import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";

/**
 * @typedef {Record<string, string>} SelectOptions
 * @typedef {Array<{uuid: string, alpha2Code: string, nameInThatLanguage: string, isDefault: boolean}>} Data
 * @typedef {{ }} Error
 * @typedef {{ selectOptions: SelectOptions, data: Data, error: Error, isLoading: boolean, mutate: undefined, isValidating: false }} LanguagesResponse
 * @return {LanguagesResponse}
 */

export function useLanguages() {
  const axiosClient = useAxiosWithAuth();
  const { data, error, isLoading } = useSWRImmutable("languages", (url) =>
    axiosClient.wrap(
      axiosClient({
        url,
      }).then((res) => res)
    )
  );

  const selectOptions =
    data !== undefined
      ? data.reduce((acc, language) => {
          acc[language.alpha2Code] = `${language.nameInThatLanguage}`;
          return acc;
        }, {})
      : {};

  return {
    selectOptions,
    data,
    error,
    isLoading,
    mutate: undefined,
    isValidating: false,
  };
}

/**
 * @typedef { 'true' } Success
 * @typedef {{ 'message': 'The selected language is invalid or not enabled.' }} ProfileLangChangeError
 * @typedef {{ language: string }} ExtraArg
 * @return {import("swr/mutation").SWRMutationResponse<Success, ProfileLangChangeError, unknown, ExtraArg>}
 */

export function useLanguageChange() {
  const axiosClient = useAxiosWithAuth();

  return useSWRMutation(["/user/profile"], ([url], { arg: { language } }) =>
    axiosClient.wrap(
      axiosClient.patch(url, {
        language,
      })
    )
  );
}
