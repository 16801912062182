import { useSWRConfig } from "swr";

export function useUserCache() {
  const { cache } = useSWRConfig();
  const swrCache = cache.keys();

  const wipe = () => {
    // Removing cashed Wallets data
    localStorage.removeItem("wagmi.store");

    // removing SWR cached queries
    for (const key of swrCache) {
      cache.delete(key);
    }
  };

  return {
    wipe,
  };
}
