import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useConversionParams } from "/context/ConversionParamsProvider";

/**
 * @returns {{
 *   btag: string | null
 * }}
 */
export function useGetReferral() {
  const [searchParams] = useSearchParams();
  const conversionParams = useConversionParams();
  const [btag, setBtag] = useState(
    searchParams.get("btag") || conversionParams.btag
  );

  useEffect(() => {
    setBtag(searchParams.get("btag") || conversionParams.btag);
  }, [searchParams, conversionParams]);

  return {
    btag,
  };
}
