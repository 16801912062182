import { useContext } from "react";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import useSWR from "swr";
import AuthContext from "/context/AuthProvider";

// TODO: This function is using "/user/profile" endpoint,
// but it should be using "/user/rank" and non-existing endpoint for current season

/**
 * Hook for getting user rank and season data
 * @typedef {{
 *   name: string,
 *   iconUrl: string,
 *   requiredXpPoints: number
 * }} RankLevel
 * @typedef {{
 *   current: RankLevel,
 *   next: RankLevel,
 *   currentXp: number,
 *   nextLevelRequiredXpPoints: number,
 *   expToNextLvl: number,
 *   isLastRank: boolean,
 *   isLastRankAndLastLvl: boolean
 * }} Rank
 * @typedef {{
 *   name: string,
 *   currentLevel: {lvl: number},
 *   nextLevel: {requiredXpPoints: number},
 *   currentXp: number
 * }} Season
 * @returns {import("swr").SWRResponse<{
 *   rank: Rank,
 *   season: Season
 * }>} SWR response
 */
export function useRankAndSeason() {
  const { auth } = useContext(AuthContext);
  const axiosClient = useAxiosWithAuth();
  const isAuthenticated = auth.token !== null;

  return useSWR(
    [isAuthenticated ? "/user/profile" : null],
    async ([url]) => {
      const data = await axiosClient.wrap(axiosClient.get(url));
      return {
        rank: data.rank,
        season: data.season,
      };
    },
    {
      keepPreviousData: true,
      revalidateOnFocus: true,
    }
  );
}
