import React, { useRef, useState } from "react";
import UserIco from "/assets/img/icons/user.svg";
import CustomButton from "/components/core/ButtonDefault";
import Arrow from "/assets/img/icons/arrow_right.svg";
import User from "/components/core/User";
import UserBalance from "/components/core/UserBalance";
import InfoMessage from "/components/core/InfoMessage";
import { useTranslation } from "react-i18next";

export default function UnLoginedButton({
  clas,
  state,
  auth,
  children,
  ...props
}) {
  const { t } = useTranslation();

  const [balance, setBalance] = useState(true);
  const mobile = state.getState().isMobile;
  const hoverRef = useRef(null);
  // const { auth } = useContext(AuthContext);

  const changeLanguage = () => {
    const lang = i18n.language === "en" ? "pl" : "en";
    i18n.changeLanguage(lang);
  };

  const openModal = ({ target }) => {
    state.setPopUp(target.dataset.open);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {auth.token ? (
          <div className="short_user_info df">
            <div className="to_profile df ai-c">
              <User
                auth={auth}
                shortInfo={true}
                mobile={mobile}
                info="1"
                state={state}
              />

              <button
                className={`df check_bal ${balance ? "" : "hide"}`}
                onClick={(e) => setBalance(!balance)}
              >
                <Arrow />
              </button>

              {balance ? (
                <div className="balance df fd-c fixel_16_md" ref={hoverRef}>
                  <p className="color-neu">{t("layout.header.balance")}</p>
                  <UserBalance />
                </div>
              ) : null}
            </div>

            {children}
          </div>
        ) : (
          <div className="buttons_sing df ai-c">
            <CustomButton
              classname="in wt"
              data-open="login"
              onClick={openModal}
              title={t("layout.header.signIn")}
            >
              <div className="icon df br-8">
                <UserIco></UserIco>
              </div>
            </CustomButton>
            <span className="line"></span>
            <CustomButton
              classname="reg wt promo"
              data-open="reg"
              onClick={openModal}
              title={t("layout.header.register")}
            />
          </div>
        )}
      </div>
      {!mobile && balance && auth.token && (
        <InfoMessage target={hoverRef}>
          <div className="tge_balance">
            <div className="info br-8">
              <p className="fixel_16_sbl">
                {t("layout.header.balanceTooltip.title")}
              </p>

              <p className="fixel_14 color-neu mr-t-20">
                {t("layout.header.balanceTooltip.paragraph1")}
              </p>
              <p className="fixel_14 color-neu mr-t-16">
                {t("layout.header.balanceTooltip.paragraph2")}
              </p>
              <p className="fixel_14 color-neu mr-t-16">
                {t("layout.header.balanceTooltip.paragraph3")}
              </p>
              <p className="fixel_14 color-neu mr-t-16">
                {t("layout.header.balanceTooltip.paragraph4")}
              </p>
            </div>
          </div>
        </InfoMessage>
      )}
    </>
  );
}
