import React from "react";
import { useTranslation } from "react-i18next";

export default function Info(props) {
  const { t } = useTranslation();
  return (
    <>
      <p className="fixel_22 text-center">
        {t("wallet.info.placeholder")}
      </p>
    </>
  );
}
