import React from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "./Layout";
import { payToLocaleString } from "/utils/utils";

// TODO: move isLoading to the parent component
export function Receive({ isLoading, data, currency, ...props }) {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Layout
        amount={t("labels.loading")}
        rate={{
          from: "TICO",
          to: currency.code,
          rate: t("labels.loading"),
        }}
        {...props}
      />
    );
  }
  // It's posible to have data without loading if it's initial state
  if (!data || data.type !== "success") {
    return (
      <Layout
        amount="?"
        rate={{
          from: "TICO",
          to: currency.code,
          rate: "?",
        }}
        {...props}
      />
    );
  }

  return (
    <Layout
      amount={payToLocaleString(data.response.to.amount)}
      rate={{
        from: data.response.to.code,
        to: data.response.from.code,
        rate: data.response.rate,
      }}
      {...props}
    />
  );
}
