import "./instrument.js";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import store from "./redux/state";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import FeatureFlagsProvider from "./context/FeatureFlagsProvider";
import AutoLoginLinkProvider from "./context/AutoLoginLinkProvider";
import ConversionParamsProvider from "./context/ConversionParamsProvider";
import Preloader from "./components/layout/Preloader.jsx";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { HelmetProvider } from "react-helmet-async";
import ErrorBoundary from "./ErrorBoundary.jsx";

const root = createRoot(document.getElementById("root"));

let rerenderEntireTree = (state) => {
  root.render(
    <I18nextProvider i18n={i18n}>
      <BrowserRouter basename={state.basePath}>
        <ConversionParamsProvider>
          <AuthProvider>
            <AutoLoginLinkProvider>
              <Preloader state={store} />
              <FeatureFlagsProvider>
                <HelmetProvider>
                  <ErrorBoundary>
                    {/* <DataProvider> */}
                    {/* <GoogleOAuthProvider clientId="837411321679-cgnr28oavedi7jabeiha7elsbcdq8adh.apps.googleusercontent.com"> */}
                    {/* <Web3Provider> */}
                    <App state={store} />
                    {/* </Web3Provider> */}
                    {/* </GoogleOAuthProvider> */}
                    {/* </DataProvider> */}
                  </ErrorBoundary>
                </HelmetProvider>
              </FeatureFlagsProvider>
            </AutoLoginLinkProvider>
          </AuthProvider>
        </ConversionParamsProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
};

rerenderEntireTree(store.getState());
store.subscribe(rerenderEntireTree);
