import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap/all'

const colors = [
  '#ffffff', //white
  '#37FA8F', //green
  '#F8532B', //orange
  '#9A44EE', //purple
  '#30D4F6', //blue
  '#FFE604' //yellow
]
export default function RankLine({ rank, state }) {
  const [isLoading, setIsLoading] = useState(true)
  const [activeStep, setActiveStep] = useState(0)
  const load = state.getState().isPageLoading
  const { totalLevelsInRank, levelProgress, currentLevel } = rank

  const prRef = useRef(null)
  const stepsRef = useRef(null)

  useEffect(() => {
    !load && setIsLoading(false)
    return () => {}
  }, [load])

  // line animation
  useEffect(() => {
    if (isLoading) return
    const el = prRef.current
    // const animationProgress = activeStep + 1
    // const partialWidthPerStep = levelProgress / currentLevel
    // const width = partialWidthPerStep * animationProgress

    const tl = gsap.timeline({
      onStart: () => el.classList.add('play'),
      onComplete: () => el.classList.remove('play')
    })

    tl.to([el], {
      backgroundColor: colors[currentLevel - 1],
      width: `${levelProgress}%`,
      duration: 1.5,
      ...{ ...(levelProgress >= 100 ? { borderRadius: '4px' } : {}) },
      ease: 'none'
    })
    return () => {
      tl.kill()
    }
  }, [isLoading, levelProgress, currentLevel])

  // // steps animation
  useEffect(() => {
    if (isLoading) return
    const steps = [...stepsRef.current.children]
    const step = steps[activeStep]

    const tl = gsap.timeline({
      onStart() {},
      onComplete() {
        setTimeout(() => {
          const next = activeStep + 1
          next < totalLevelsInRank && next < currentLevel && setActiveStep(next)
        }, 400)
      }
    })

    step.classList.add('active')
    tl.to(
      step,
      {
        backgroundColor: 'white',
        boxShadow: '0px 0px 8px 2px #ffffff80',
        duration: 0.1
        // repeat: 1
        // yoyo: true
      },
      'puls'
    )
    tl.to(
      step,
      {
        boxShadow: '0px 0px 0px 0px #ffffff80',
        duration: 0.1
      },
      'end'
    )

    return () => {
      tl.revert().kill()
    }
  }, [activeStep, isLoading, levelProgress, currentLevel, totalLevelsInRank])

  return (
    <div className="progress df fd-c w-full my_line_progress">
      <div className="steps df w-full" ref={stepsRef}>
        {Array(totalLevelsInRank || 1)
          .fill()
          .map((l, i) => (
            <span key={i} className={i < activeStep ? 'done' : ''}></span>
          ))}
      </div>
      <div className="line w-full">
        <div className="l" ref={prRef}></div>
      </div>
    </div>
  )
}
