import React from "react";
import * as styles from "./BlinkingText.module.scss";

import live1 from "/assets/img/promotion/live-desktop/live-1.png";
import live2 from "/assets/img/promotion/live-desktop/live-2.png";
import live3 from "/assets/img/promotion/live-desktop/live-3.png";
import live4 from "/assets/img/promotion/live-desktop/live-4.png";
import live5 from "/assets/img/promotion/live-desktop/live-5.png";
import live6 from "/assets/img/promotion/live-desktop/live-6.png";

import tico1 from "/assets/img/promotion/tico/tico-1.png";
import tico2 from "/assets/img/promotion/tico/tico-2.png";

const live = [live1, live2, live3, live4, live5, live6];

const ticos = [tico1, tico2];

export const Live = () => {
  return (
    <span className={`${styles.wrapper} ${styles.wrapper_live}`}>
      {live.map((image, i) => (
        <img key={`live-${i}`} src={image} alt="Live" className={styles.live} />
      ))}
    </span>
  );
};

export const Tico = () => {
  return (
    <span className={`${styles.wrapper} ${styles.wrapper_tico}`}>
      {ticos.map((image, i) => (
        <img key={`tico-${i}`} src={image} alt="Tico" className={styles.tico} />
      ))}
    </span>
  );
};
