import { useState, useEffect } from "react";
import { useLanguages } from "/api/user/language-change";
import i18n from "/i18n/i18n";
import { getUnifiedLanguageCode } from "/utils/extractBrowserLanguage";

/**
 * @returns {{ detectedLanguage: {
 * uuid: string,
 * alpha2Code: string,
 * isDefault: boolean,
 * } | null }}
 */
export function useDetectLanguage() {
  const { data: languages } = useLanguages();
  const [detectedLanguage, setDetectedLanguage] = useState(null);

  useEffect(() => {
    setDetectedLanguage(() => {
      const browserLang = getUnifiedLanguageCode(i18n.language);
      if (!languages) {
        return null;
      }

      try {
        return languages.find((lang) => lang.alpha2Code === browserLang);
      } catch {
        return languages.find((lang) => lang.isDefault);
      }
    });
  }, [languages]);

  return {
    detectedLanguage,
  };
}
