import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "/components/core/ButtonDefault";
import { payToLocaleString } from "/utils/utils";
import { BigDecimal } from "./money";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import { useWithdrawableBalance } from "/api/cashier/balance";
import { useCreateWithdrawalRequest } from "/api/cashier/withdrawal/create-withdrawal-request";

/**
 * 1. This screen should provide full details about the withdrawal without any hidden details.
 * 2. The fields should be listed in "math" opreration order
 * @param {{
 *  changePart: (path: string, queryParams: import("./queryParams").WithdrawalQueryParams) => void,
 *  kycStatus: import("/api/user/kyc-status").UseKycStatus, 
 * changeTab, amountState, addressState, onConfirm, data: import("./queryParams").WithdrawalQueryParams }} props
 * @returns
 */
export default function Confirm({
  changePart,
  data: { amountValue, addressValue, chain },
  onConfirm,
}) {
  const { t } = useTranslation();
  const axiosClient = useAxiosWithAuth();
  const balance = useWithdrawableBalance(
    axiosClient,
    {
      refreshInterval: 25000,
    }
  );

  const pay = useCreateWithdrawalRequest(
    axiosClient,
    {
      onSuccess: (data, key, config) => {
        console.log("withdrawal onSuccess", data, key, config);
      },
    }
  );

  const new_balance = () =>
    balance.data && balance.data?.withdrawableBalance && amountValue
      ? new BigDecimal(balance.data?.withdrawableBalance).minus(
          new BigDecimal(amountValue)
        )
      : new BigDecimal(0);

  return (
    <div className="withdraw-confirm df fd-c" data-hide-top>
      <h2 className="fixel_26 text-center">{t("wallet.withdraw.title")}</h2>
      <div className="tico-information df fd-c gap-16">
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">{t("wallet.withdraw.withdrawingTo")}</p>
          <p className="fixel_16_sbl">{addressValue}</p>
        </div>
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">{t("wallet.withdraw.amountToWithdraw")}</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">{payToLocaleString(amountValue)}</p>
          </div>
        </div>
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">{t("wallet.withdraw.serviceFee")}</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">20</p>
          </div>
        </div>
        <div className="row df jc-sb ai-c gap-10">
          <p className="fixel_14 color-neu">{t("wallet.withdraw.availableToWithdraw")}</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p className="fixel_16_sbl">{balance.data?.withdrawableBalance}</p>
          </div>
        </div>
        <div className="full-line"></div>

        <div className="row df jc-sb ai-c gap-10 fixel_22">
          <p>{t("wallet.withdraw.balanceAfterWithdrawal")}</p>
          <div className="df ai-c gap-6">
            <div className="tico_icon"></div>
            <p>{payToLocaleString(new_balance().toFixed(0))}</p>
          </div>
        </div>
      </div>

      {pay.error && pay.error.message}

      <div className="buttons df jc-sb">
        <CustomButton
          onClick={() => changePart("flow", { amountValue, addressValue })}
          classname={`grey`}
          def={40}
          title={t("labels.back")}
        />
        <CustomButton
          onClick={async () => {
            console.log("withdrawal", {
              amount: amountValue,
              address: addressValue,
              chainId: chain,
            });
            await pay.trigger({
              amount: amountValue,
              address: addressValue,
              chainId: chain.chainId,
            });

            onConfirm();
          }}
          disabled={(new_balance().lt(0) && pay.isMutating) || pay.error}
          classname={`pr`}
          def={40}
          title={t("wallet.withdraw.confirmWithdrawal")}
        />
      </div>
    </div>
  );
}
