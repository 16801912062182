import React from "react";
import {
  WagmiProvider,
  createConfig,
  // http,
  serialize,
  deserialize,
} from "wagmi";
import { mainnet, base, polygon, avalanche } from "wagmi/chains";
import { QueryClient } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";

const config = createConfig(
  getDefaultConfig({
    // Your dApp's info
    appName: "Funtico",
    walletConnectProjectId: import.meta.env.VITE_REOWN_PROJECT_ID,
    chains: [mainnet, base, polygon, avalanche],
    // transports: {
    //   // RPC URL for each chain
    //   [mainnet.id]: http(
    //     `https://eth-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_ID}`
    //   ),
    //   [base.id]: http(
    //     `https://base-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_ID}`
    //   ),
    //   [polygon.id]: http(
    //     `https://polygon-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_ID}`
    //   ),
    // },
    // Optional App Info
    appDescription:
      "Funtico is a new, exciting gaming platform, that takes your favorite genres to unprecedented heights! Your skills aren't just a matter of pride; they unlock incredible prizes and exclusive perks.",
    appUrl: "https://funtico.com",
    appIcon: "https://funtico.com/favicon/apple-touch-icon.png",
  })
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1_000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  serialize,
  storage: window.sessionStorage,
  deserialize,
});

export function Web3Provider({ children }) {
  return (
    <WagmiProvider config={config}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <ConnectKitProvider>{children}</ConnectKitProvider>
      </PersistQueryClientProvider>
    </WagmiProvider>
  );
}
