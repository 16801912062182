import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Input from "/components/core/labels/Input";

export default function Verify({ state }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="title">
        <p className="t fixel_36">{t("profile.kycPopupTitle")}</p>
      </div>

      <div className="content_data df fd-c">
        <form className="form df fd-c">
          <div className="form_content">
            <Input placeholder="Enter code" />
          </div>

          <div className="switch_form jc-c fw as-c df">
            <p className="fixel_16_sbl">{t("profile.kycPopupCode")}</p>
            <button className="def wt" type="button">
              {t("profile.kycSendAgain")}
            </button>
          </div>
          <button className="def submit type-40 pr as-c br-16">
            {t("labels.confirm")}
          </button>
        </form>
      </div>
      <div className="switch_form jc-c fw as-c df">
        <p className="fixel_16_sbl">{t("profile.kycPopupEmailMessage")}</p>
        <button className="def wt" onClick={(e) => state.setPopUp("write")}>
          {t("profile.kycPopupCta")}
        </button>
      </div>
    </>
  );
}
