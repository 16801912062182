import useSWRMutation from "swr/mutation";
import store from "/redux/state";
import { useContext } from "react";
import AuthContext from "/context/AuthProvider";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";

/**
 * Hook for handling wallet authentication
 * @typedef {{
 *   wallet: string,
 *   signature: string,
 *   message: string,
 *   name?: string,
 *   language_id?: string,
 *   btag?: string,
 *   page?: string
 * }} AuthData
 * @typedef {{
 *   success: boolean,
 *   token?: string,
 *   pid?: number
 * }} Response
 * @typedef {{
 *   message?: string
 * }} Error
 * @returns {import("swr/mutation").SWRMutationResponse<Response, Error>} SWR mutation response
 */
export function useWalletAuth() {
  const axiosClient = useAxiosWithAuth();
  const { setAuth } = useContext(AuthContext);

  return useSWRMutation(
    "auth/wallet",
    async (_, { arg: authData }) => {
      const response = await axiosClient.wrap(
        axiosClient.post("auth/wallet", authData)
      );

      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.success) {
          setAuth({ ...data });
          store.setPopUp(false);
        }
      },
    }
  );
}
